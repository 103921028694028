<template>

    <!-- <div class="mx-auto bg-Sky-300  w-full "> -->
        <div class=" flex flex-col items-center justify-center w-full bg-Cyan-700">
            <div class="text-xs sm:text-sm  font-medium text-blue-50 w-full text-center ">Agendamento Vicentino Online</div>
            <div  class="text-xs sm:text-sm  font-medium  text-blue-50 w-full text-center">Todos os Direitos Reservados &copy; {{new Date().getFullYear()}} - Versão  2.0  </div>
            <div class="text-xs sm:text-sm font-mediun text-blue-50  py-1 w-full text-center">Desenvolvido pelo Setor de TI - ERVSVP</div>
        </div>
    <!-- </div> -->

    
</template>
